<template lang="html">
  <div class="app">
    <header id="header" :class="{'shrink' : scrollPosition > 100}">
      <router-link to="/" tag="div">
        <h1>3StrandsDevelopment</h1>
        <h3>iOS Apps for Students of all ages</h3>
      </router-link :to="/" tag="div">
    </header>
    <div class="centered">
    <div class="main-container">
        <nav>
            <ul class="app-list">

              <router-link v-for="appId in apps" :key="appId" :to="`/apps/${appId}`" tag="li" class="app-icon" active-class="selected" :data-id="appId"><a>
                <img :src="iconUrlForApp(appId)" alt="">
              </a></router-link>

            </ul>
        </nav>
        <section>
          <transition :name="slideTransition" mode="out-in" appear>
          <router-view :key="'a' + $route.params.id || ''" @show-support="showModal = true"></router-view>
          </transition>
          <div class="copyright">
            copyright 2019 3StrandsDevelopment.com
          </div>
        </section>
    </div>
    </div>
    <support-modal class="" v-if="showModal" :selected-app="selectedApp" @close="showModal = false"></support-modal>
    </div>
</template>

<script>
import AppDetails from './components/AppDetails';
import SupportModal from './components/SupportModal';
import Model from './modules/model.js';

export default {
  name: 'app',
  components: {
    AppDetails,
    SupportModal
  },
  props: [],
  firebase: {},
  data() {
    return {
      apps: Object.keys(Model.apps),
      selectedApp: Model.apps['timeline-builder'],
      showModal: false,
      slideTransition: 'slide-up',
      scrollPosition: 0
    };
  },
  computed: {},
  methods: {
    showApp(appId) {
      this.$router.push({ path: `apps/${appId}` });
    },
    iconUrlForApp(appId) {
      return Model.apps[appId].icon;
    },
    scrolled(p) {
      console.log('scrolled');
      console.log(p);
    }
  },
  mounted() {
    this.$router.beforeEach((to, from, next) => {
      const toId = to.params.id;
      const fromId = from.params.id;

      if (toId && fromId) {
        const toIdx = this.apps.indexOf(toId);
        const fromIdx = this.apps.indexOf(fromId);
        this.slideTransition = (toIdx < fromIdx && 'slide-down') || 'slide-up';
      }

      next();
    });

    window.addEventListener('scroll', () => {
      this.scrollPosition = window.scrollY;
    });
  }
};
</script>

<style lang="css" scoped>

.slide-up-enter-active,
.slide-up-leave-active,
.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 0.5s ease;
}
.slide-up-enter,
.slide-up-leave-to,
.slide-down-enter,
.slide-down-leave-to{
  opacity: 0;
}

.slide-up-enter, .slide-down-leave-to {
  transform: translateY(30px);
}
.slide-down-enter, .slide-up-leave-to {
  transform: translateY(-30px);
}

</style>

<style src="./styles/main.css" lang="css"></style>
