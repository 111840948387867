<template lang="html">
  <div class="welcome">
    <img src="../assets/welcome-splash.jpg"/>
    <div class="has-text-centered">
        <a class="button is-medium is-grey" @click="$emit('show-support')">Support</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'welcome',
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {
    if (this.$route.name == 'support') {
      this.$emit('show-support');
    }
  }
};
</script>

<style lang="css" scoped>
img {
  margin: auto;
}
</style>
