<template lang="html">
  <div class="support-success">
      <div class="centered-content">
        <svg xmlns="http://www.w3.org/2000/svg" width="50" viewBox="0 0 20 20">
<path fill="#50d9ba" d="M9.5 20c-2.538 0-4.923-0.988-6.718-2.782s-2.782-4.18-2.782-6.717c0-2.538 0.988-4.923 2.782-6.718s4.18-2.783 6.718-2.783c2.538 0 4.923 0.988 6.718 2.783s2.782 4.18 2.782 6.718-0.988 4.923-2.782 6.717c-1.794 1.794-4.18 2.782-6.718 2.782zM9.5 2c-4.687 0-8.5 3.813-8.5 8.5s3.813 8.5 8.5 8.5 8.5-3.813 8.5-8.5-3.813-8.5-8.5-8.5z"></path>
<path fill="#50d9ba" d="M7.5 14.5c-0.128 0-0.256-0.049-0.354-0.146l-3-3c-0.195-0.195-0.195-0.512 0-0.707s0.512-0.195 0.707 0l2.646 2.646 6.646-6.646c0.195-0.195 0.512-0.195 0.707 0s0.195 0.512 0 0.707l-7 7c-0.098 0.098-0.226 0.146-0.354 0.146z"></path>
</svg>
        <h1 class="title">Support Request Received</h1>
        <h3 class="subtitle">Thank you, your support request has been received and will be reviewed as soon as possible.</h3>
        <div class="sub-links">
          <a class="shadowed" href="/">Home</a>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "support-success",
  // share common functionality with component mixins
  mixins: [],
  props: {},
  // variables
  data() {
    return {};
  },
  computed: {},
  components: {},
  // methods
  watch: {},
  methods: {}
};
</script>

<style lang="css" scoped>
.support-success {
  margin: 5em 2em;
}
.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
}
</style>
