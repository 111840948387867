<template lang="html">
    <transition name="modal">
      <div class="modal is-active">
        <div class="modal-background" @click="$emit('close')"></div>
          <div class="modal-card">
          <!-- <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdvgtnR1c9I1WCHspSw4oQmCI_VFMNy3Ue0AV2tKFC-MbU8cg/viewform?embedded=true" frameborder="0">Loading...</iframe> -->
          <header class="modal-card-head">
            <div class="">
              <h2>Support</h2>
              <p>Provide feedback or get help with issues you are experiencing on one of our apps.</p>
            </div>
          </header>
          <section class="modal-card-body">
            <form ref="supportForm" action="https://submit.jotform.us/submit/72603323178150/" method="post" name="form_72603323178150" id="72603323178150" accept-charset="utf-8">
                <input type="hidden" name="formID" value="72603323178150" />
                <div class="field">
                  <label class="label" for="email">Email*</label>
                  <p class="control has-icons-left has-icons-right">
                    <input name="q3_email" id="input_3" v-validate="'required|email'" :class="{'is-danger': errors.has('q3_email') }" class="input" type="text" placeholder="Email Address">
                    <span class="icon is-small is-left">
                      <i class="fa fa-envelope"></i>
                    </span>
                  </p>
                  <label class="help">We will only ever use this address to respond to your feedback.</label>
                </div>

                <div class="field">
                  <label class="label" for="app">App</label>
                  <p class="control">
                    <span class="select">
                      <select name="q4_app" id="input_4" v-validate="'required'" required v-model="selectedId">
                        <option value="none-selected" selected disabled>Select One</option>
                        <option v-for="app in apps" :value="app.id">{{ app.name }}</option>
                      </select>
                    </span>
                  </p>
                  <span v-show="errors.has('q4_app')" class="help is-danger">This field is required.</span>
                </div>

                <label class="label" for="device">Device</label>
                <div class="field has-addons">
                  <p class="control">
                    <span class="select">
                      <select name="q5_device" id="input_5">
                        <option value="iPhone" selected="">iPhone</option>
                        <option value="iPad">iPad</option>
                        <option value="Other">Other</option>
                      </select>
                    </span>
                  </p>
                  <p class="control">
                    <input name="q8_device-type" id="input_8" class="input" placeholder="Type | 8+, Pro, 10" />
                  </p>
                  <p class="control">
                    <input name="q6_ios-version" id="input_6" v-validate="'max:10'" class="input" :class="{'is-danger': errors.has('deviceOs') }" type="text" placeholder="iOS | 10.3, 11" />
                  </p>
                </div>
                <span v-show="errors.has('deviceOs')" class="help is-danger">{{ errors.first('deviceOs') }}</span>

                <div class="field">
                  <label class="label">Feedback*</label>
                  <p class="control">
                    <textarea name="q7_typeA" id="input_7" v-validate="'required'" class="textarea" :class="{'is-danger': errors.has('q7_typeA') }" placeholder="Your feedback..."></textarea>
                  </p>
                  <label class="help" :class="{'is-danger' : errors.has('q7_typeA')}">Please explain any issues you are experiencing.</label>
                </div>
                <div class="field is-grouped">
                  <p class="control">
                    <button type="submit" id="input_2" class="button is-medium is-primary" @click="submit">Submit</button>
                  </p>
                  <p class="control">
                    <button class="button is-medium is-light" @click="close">Cancel</button>
                  </p>
                </div>
            </form>
          </section>
          </div>
      </div>
    </transition>
</template>

<script>
import Model from '../modules/model';

export default {
  name: 'support-modal',
  props: ['selectedApp'],
  data() {
    return {
      apps: Model.apps,
      selectedId: 'none-selected'
    };
  },
  computed: {
    appIds() {
      return Object.keys(this.apps);
    },
    // selectedId() {
    //   if (this.$route.params.id) {
    //     return this.$route.params.id;
    //   }
    //   return 'none-selected';
    // }
  },
  methods: {
    submit(event) {
      event.preventDefault();

      this.$validator
        .validateAll()
        .catch(error => {
          console.log(this.errors);
        })
        .then(() => {
          if (!this.errors.any()) {
            this.$refs.supportForm.submit();
          }
        });
    },
    close(event) {
      event.preventDefault();
      this.$emit('close');
    }
  },
  mounted() {
    this.selectedId = this.$route.params.id || 'none-selected'
  }
};
</script>

<style lang="css" scoped>

h2 {
  font-size: 1.5em;
  color: white;
}

p {
  font-size: 1em;
  display: block;
  color: #45BCA1;
}

.modal-card-head {
  padding: 20px;
  background-color: #87E3CE;
  height: auto;
}

button {
  font-weight: normal;
  border-width: 0;
}

button:hover, button:visited {
  color: white;
}

@media all and (min-width: 767px) {
  .modal-card-head, .modal-card-body {
    padding: 40px;
  }
}

.modal-enter-active, .modal-leave-active {
  transition: opacity .3s;
}
.modal-enter, .modal-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
