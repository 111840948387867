import Vue from "vue";
import Router from "vue-router";

import Welcome from "../components/Welcome";
import AppDetails from "../components/AppDetails";
import Support from "../components/SupportModal";
import SupportSuccess from "../components/SupportSuccess";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    { path: "", name: "home", component: Welcome, props: true },
    { path: "/apps/:id", component: AppDetails, props: true },
    { path: "/support", name: "support", component: Welcome },
    { path: "/support-received", name: "support-reveived", component: SupportSuccess },
    { path: "*", redirect: { name: "home" } }
  ]
});
