<template lang="html">
    <div class="app-details featured-app" v-bind:data-id="app.id">
      <h1 class="title">{{ app.name }}</h1>
      <h3 class="subtitle">{{ app.subtitle }}</h3>
      <div v-if="app.description" class="description" v-html="app.description"></div>
      <div class="sub-links">
        <a class="shadowed" :href="app.storeURL" target="_blank">View on App Store</a>
        <a class="shadowed" @click="$emit('show-support')">Support</a>
      </div>
    </div>
</template>

<script>
import Model from '../modules/model';
import Vue from 'vue';

export default {
  name: 'app-details',
  props: ['id'],
  data() {
    return {
      fallbackId: 'timeline-builder'
    };
  },
  computed: {
    app() {
      const id = this.id ? this.id : this.fallbackId;
      const app = Model.apps[id];
      return app;
    }
  },
  methods: {}
};
</script>

<style lang="css" scoped>
</style>
