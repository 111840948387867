import Vue from 'vue';
import App from './App';
import router from './router';

import Validation from 'vee-validate';

Vue.use(Validation, {
  strict: false
});

import VueResource from 'vue-resource';

Vue.use(VueResource);

Vue.config.productionTip = false;

/* eslint-disable no-new */

new Vue({
  el: '#app',
  router,
  http: { root: './' },
  render: h => h(App)
});
