export default {
  apps: {
    'timeline-builder': {
      name: 'Timeline Builder',
      id: 'timeline-builder',
      icon: require('../assets/img/itunes-artwork/timeline-builder.png'),
      subtitle: 'Create and Design Custom Timelines',
      description: require(`../assets/descriptions/timeline-builder.md`),
      storeURL:
        'https://itunes.apple.com/us/app/timeline-builder-create-design/id733989611?ls=1&mt=8'
    },
    'time-my-drive': {
      name: 'Time My Drive',
      id: 'time-my-drive',
      icon: require('../assets/img/itunes-artwork/time-my-drive.png'),
      subtitle: 'Teen and Student Driver Log and Stopwatch',
      description: require(`../assets/descriptions/time-my-drive.md`),
      storeURL:
        'https://itunes.apple.com/us/app/timemydrive-teen-student-driver/id407377881?ls=1&mt=8'
    },
    'spectra-sketch': {
      name: 'Spectra Sketch',
      id: 'spectra-sketch',
      icon: require('../assets/img/itunes-artwork/spectra-sketch.png'),
      subtitle: 'Line Art and Symmetry Drawing',
      description: require(`../assets/descriptions/spectra-sketch.md`),
      storeURL:
        'https://itunes.apple.com/us/app/spectra-sketch-line-art-symmetry/id449504639?ls=1&mt=8'
    },
    'inspire-me': {
      name: 'Inspire Me',
      id: 'inspire-me',
      icon: require('../assets/img/itunes-artwork/inspire-me.png'),
      subtitle: 'Wallpaper and Word Pictures creator',
      description: require(`../assets/descriptions/inspire-me.md`),
      storeURL:
        'https://itunes.apple.com/us/app/inspire-me-wallpaper-word/id689682140?ls=1&mt=8'
    }
  }
};
